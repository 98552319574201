import {
  DirectoryServiceParam,
  DirectoryServiceResponseBody,
  DirectoryServiceResponse,
} from '@core/@models/DirectoryServiceModel';
import { ApiService } from '@core/services/api.service';
import { TablePaginationConfig } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';

export interface FetchingDataPagination {
  data: DirectoryServiceResponseBody[];
  loading: boolean;
  pagination: TablePaginationConfig;
  sortOrder: SorterResult<DirectoryServiceResponseBody>;
  fetchData: (queryParam: DirectoryServiceParam) => void;
  setOffset: Dispatch<SetStateAction<number>>;
  handleTableChange: (
    tablePagination: TablePaginationConfig,
    sorter: SorterResult<DirectoryServiceResponseBody>
  ) => void;
  resetTableConfig: () => void;
  resetCurrentPage: () => void;
}

export const useDirectoryDataFetchingPagination = (
  queryParam: DirectoryServiceParam,
  url: string
): FetchingDataPagination => {
  const apiService = React.useMemo(() => new ApiService(url), []);
  const [data, setData] = useState<DirectoryServiceResponseBody[]>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [offset, setOffset] = useState<number>(0);
  const totalElements = useRef<number>(0);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    showSizeChanger: false,
    showTotal: (total) => t('common:totalItem', { total }),
  });
  const [sortOrder, setSortOrder] = useState<
    SorterResult<DirectoryServiceResponseBody>
  >({
    field: 'modifyDate',
    order: 'descend',
  });

  useEffect(() => {
    if (queryParam.folderId === null) return;
    fetchData(queryParam);
  }, [queryParam]);

  useEffect(() => {
    const total = offset + totalElements.current;
    setPagination({ ...pagination, total });
  }, [offset]);

  const handleTableChange = (
    tablePagination: TablePaginationConfig,
    sorter: SorterResult<DirectoryServiceResponseBody>
  ) => {
    setSortOrder(sorter);
    const { current: current, pageSize: size } = tablePagination;
    if (current === undefined || current === pagination.current) {
      resetCurrentPage();
      return;
    }
    setPagination(Object.assign({}, pagination, tablePagination));
    const param = Object.assign({}, { ...queryParam, size });
    if (current * 15 <= offset || (current - 1) * 15 <= offset) {
      const page =
        current - Math.ceil(offset / 15) < 0
          ? 1
          : current - Math.ceil(offset / 15) + 1;
      fetchData({ ...param, page }, current);
    } else {
      fetchDataTwoPage({ ...param }, current);
    }
  };

  const fetchData = (param: DirectoryServiceParam, current?: number) => {
    console.log(`fetchData with page ${param.page}, current ${current}`);
    setLoading(true);
    apiService
      .getDataPagable<DirectoryServiceResponse>(param)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: DirectoryServiceResponse) => {
          if (current === undefined) current = 1;
          totalElements.current = result.totalElements;
          setData(generateDataKey(result.content));
          setPagination({
            ...pagination,
            current,
            pageSize: param.size,
            total: result.totalElements + offset,
          });
        },
        error: () => {
          resetPagination();
        },
      });
  };

  const fetchDataTwoPage = (param: DirectoryServiceParam, current: number) => {
    console.log(`fetchDataTwoPage with current ${current}`);
    setLoading(true);
    let page = Math.floor((current * 15 - offset) / 15);
    //TODO: Refactor code
    apiService
      .getDataPagable<DirectoryServiceResponse>({ ...param, page })
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: DirectoryServiceResponse) => {
          const { content: fContent } = result;
          page = page + 1;
          apiService
            .getDataPagable<DirectoryServiceResponse>({ ...param, page })
            .pipe(finalize(() => setLoading(false)))
            .subscribe({
              next: (r: DirectoryServiceResponse) => {
                if (current === undefined) current = 1;
                totalElements.current = r.totalElements;
                setData(generateDataKey(fContent.concat(r.content)));
                setPagination({
                  ...pagination,
                  current,
                  pageSize: param.size,
                  total: r.totalElements + offset,
                });
              },
              error: () => {
                resetPagination();
              },
            });
        },
        error: () => {
          resetPagination();
        },
      });
  };

  const generateDataKey = (
    content: DirectoryServiceResponseBody[]
  ): DirectoryServiceResponseBody[] => {
    return content.map((v: DirectoryServiceResponseBody) => ({
      ...v,
      key: v.id,
    }));
  };

  const resetTableConfig = () => {
    resetCurrentPage();
    setSortOrder({
      field: 'modifyDate',
      order: 'descend',
    });
  };

  const resetCurrentPage = () => {
    const current = 1;
    setPagination({ ...pagination, current });
  };

  const resetPagination = () => {
    setData([]);
    const current = 1;
    const total = 0;
    setPagination({ ...pagination, current, total });
  };

  return {
    data,
    loading,
    pagination,
    sortOrder,
    handleTableChange,
    fetchData,
    setOffset,
    resetTableConfig,
    resetCurrentPage,
  };
};
