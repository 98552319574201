import { DropdownProvider } from '@contexts/DropdownProvider';
import { SearchProvider } from '@contexts/SearchProvider';
import { DirectoryServiceParam } from '@core/@models/DirectoryServiceModel';
import { SortDirection } from '@core/@models/PaginationModal';
import { Row, Col } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionMenu } from './ActionMenu';
import { DirectoryList } from './DirectoryList';
import { DirectoryTreePage } from './DirectoryTreePage';

export const DirectoryService: React.FC = () => {
  const { t } = useTranslation();
  const [currentPath, setCurrentPath] = useState<string>();
  const handleCurrentPath = (path: string) => setCurrentPath(path);
  const initialParam: DirectoryServiceParam = {
    folderId: null,
    search: null,
    begin: null,
    end: null,
    page: 1,
    size: 15,
    sort: `modifyDate,${SortDirection.DESC}`,
  };

  return (
    <DropdownProvider>
      <Layout>
        <Row className="current-path">
          <Col flex="110px">{t('common:currentPath')}:</Col>
          <Col flex="auto" role="current-path">
            {currentPath}
          </Col>
        </Row>
        <Layout>
          <Sider
            theme={'light'}
            width={390}
            style={{ borderRight: '1px solid #FCB03480' }}
          >
            <DirectoryTreePage handleCurrentPath={handleCurrentPath} />
          </Sider>
          <Content style={{ backgroundColor: 'white' }}>
            <SearchProvider<DirectoryServiceParam> initialParam={initialParam}>
              <DirectoryList />
            </SearchProvider>
          </Content>
          <ActionMenu />
        </Layout>
      </Layout>
    </DropdownProvider>
  );
};
